import PlainPage from "@app/layouts/PlainPage/plainpage";
import MetaData from "@components/Modules/MetaData";
import { Typography } from "@sphtech/dmg-design-system";
import { ReactElement } from "react";

import { configSite } from "../config";
import shareImagePath from "./assets/hbs-about-us.jpg?url";

const { Body, Heading } = Typography;

export default function AboutUsPage(): ReactElement {
  return (
    <PlainPage ads={false} headingTitle="About Us">
      <MetaData
        title="About Us"
        type="WebPage"
        path="/about-us"
        image={{
          src: `${configSite.address}${shareImagePath}`,
          alt: "",
          srcWidth: 1580,
          srcHeight: 593,
        }}
      />
      <Body>
        “Harper’s BAZAAR Singapore is your front row access to the best in
        fashion, beauty, style, jewels, watches, celebrity and living a
        fashionable life. A visual feast and an unparalleled source of
        inspiration, BAZAAR Singapore indulges the senses and excites readers
        with great reads, fantastic shopping ideas and cutting-edge photography.
        With up-to-the-minute updates on everything from the runways to
        realways, from Singapore to Paris, this website will be your go-to
        source for the latest fashion, beauty, luxury and celebrity news.”
      </Body>
      <br />
      <Body>
        <strong>Kenneth Goh, Editor-in-Chief</strong>
      </Body>
      <br />

      <Heading.SubHeading2>Our Team</Heading.SubHeading2>
      <Body>
        <strong>Editor-in-Chief</strong> Kenneth Goh
      </Body>
      <Body>
        <strong>Creative Director</strong> Windy Aulia
      </Body>
      <Body>
        <strong>Deputy Editor</strong> Renée Batchelor
      </Body>
      <br />
      <Body>
        <strong>FASHION</strong>
      </Body>
      <Body>
        <strong>Fashion Editor</strong> Aaron Kok
      </Body>
      <Body>
        <strong>Style Editor</strong> Gracia Phang
      </Body>
      <br />
      <Body>
        <strong>BEAUTY</strong>
      </Body>
      <Body>
        <strong>Senior Beauty Editor</strong> Arissa Ha
      </Body>
      <br />
      <Body>
        <strong>DIGITAL</strong>
      </Body>
      <Body>
        <strong>Digital Director</strong> Annabelle Fernandez
      </Body>
      <Body>
        <strong>Senior Digital Writer</strong> Shermin Ng
      </Body>
      <Body>
        <strong>Content Producer</strong> Brandon Chia
      </Body>
      <br />
      <Body>
        <strong>DESIGN</strong>
      </Body>
      <Body>
        <strong>Senior Art Director</strong> Daphne Tso
      </Body>
      <Body>
        <strong>Associate Art Director</strong> Alice Chua
      </Body>
      <br />
      <br />
    </PlainPage>
  );
}
