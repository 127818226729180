import PubBaseLayout from "@pub/BaseLayout";
import { configHome } from "@pub/config";
import { SocialIcons, Typography } from "@sphtech/dmg-design-system";
import AppNewsletter from "@src/app/components/Modules/Newsletter/AppNewsletter";
import { ReactNode } from "react";

import styles from "./plainpage.module.css";

const { Heading } = Typography;

export type PlainPageProps = {
  headingTitle: string;
  children: ReactNode;
  ads?: boolean;
};

/**
 * PlainPageLayout
 * Description: Layout for Contact Us page
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */

export default function PlainPage({
  headingTitle,
  children,
  ads,
}: PlainPageProps): ReactNode {
  const socialMedia = {
    target: "_blank",
    icons: [
      {
        name: "Facebook",
        href: "https://www.facebook.com/harpersbazaarsingapore/",
      },
      {
        name: "X",
        href: "https://x.com/HarpersBazaarSG",
      },
      {
        name: "Youtube",
        href: "https://www.youtube.com/harpersbazaarsingaporemagazine",
      },
      {
        name: "Instagram",
        href: "https://www.instagram.com/harpersbazaarsg/",
      },
      {
        name: "Tiktok",
        href: "https://www.tiktok.com/@harpersbazaarsg",
      },
      {
        name: "RSS",
        href: "/feed",
      },
    ],
  };

  const newsLetterProps = {
    backgroundImage: configHome.newsletter?.containerImageBg || "",
    newsletterCtaText: "SIGN UP",
    emailPlaceHolder: {
      placeholder: "EMAIL ADDRESS",
    },
    newsletterBody: configHome.newsletter?.newsletterBody || "",
  };

  return (
    <PubBaseLayout ads={ads}>
      <div className={styles.background}>
        <div className={styles.bodyContainer}>
          <div className={styles.textContainer}>
            <div className={styles.titleContainer}>
              <Heading.SectionTitle>{headingTitle}</Heading.SectionTitle>
            </div>

            {children}
            <Heading.SubHeading3>
              <strong>Follow Us</strong>
            </Heading.SubHeading3>
            <div className={styles.socialMediaContainer}>
              <SocialIcons {...socialMedia} />
            </div>
          </div>
          <div className={styles.newsletterContainer}>
            <AppNewsletter {...newsLetterProps} />
          </div>
        </div>
      </div>
    </PubBaseLayout>
  );
}
