import { Icons } from "@sphtech/dmg-design-system";

import { ConfigHeader } from "../../types/config";

export const configHeader: ConfigHeader = {
  megaMenu: {
    newsletter: {
      text: "Stay updated in technology trends gadget shootouts, and geeky life hacks you never knew you couldn't live without.",
      buttonText: "SIGN UP",
      url: "/newsletter",
    },
    closeButton: {
      type: "text",
      placement: "right",
      TextOrIcon: "Close",
    },
  },
  socialMedia: {
    target: "_blank",
    icons: [
      {
        icon: Icons.facebookIcon,
        name: "Facebook",
        href: "https://www.facebook.com/harpersbazaarsingapore/",
      },
      {
        icon: Icons.TwitterIcon,
        name: "Twitter",
        href: "https://x.com/HarpersBazaarSG",
      },
      {
        icon: Icons.YoutubeIcon,
        name: "Youtube",
        href: "https://www.youtube.com/harpersbazaarsingaporemagazine",
      },
      {
        icon: Icons.InstagramIcon,
        name: "Instagram",
        href: "https://www.instagram.com/harpersbazaarsg/",
      },
      {
        icon: Icons.TiktokIcon,
        name: "Tiktok",
        href: "https://www.tiktok.com/@harpersbazaarsg",
      },
    ],
  },
};
