import {
  BlackArrowLeft,
  BlackArrowRight,
  PlayButtonWhite,
  whiteArrowLeft,
  whiteArrowRight,
} from "@app/theme/icons/commonIcons";
import {
  Ads,
  Arrow,
  LandscapeScroll,
  PortraitScroll,
  Typography,
  VideoGalleryLandscape,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { Fragment, ReactNode } from "react";

import styles from "./videolanding.module.css";

const { Heading, Label } = Typography;

type Props = {
  data: {
    name?: string;
    subHeading?: string;
    anchorListTitle?: string;
    anchorList?: React.ComponentProps<typeof LandscapeScroll>["items"];
    playlists?: {
      title?: string;
      items: React.ComponentProps<typeof LandscapeScroll>["items"];
    }[];

    lb1?: ReactNode;
    lb2?: ReactNode;
  };
};

function VideoLandingLayout({ data }: Props): ReactNode {
  return (
    <Fragment>
      {/* Video Information (optional) */}
      <section
        className={cx(styles.bodyContainer, styles.videoLandingContainer)}
      >
        {data.name && (
          <div className={styles.headingContainer}>
            <Heading.SectionTitle>{data.name}</Heading.SectionTitle>
            {data.subHeading && <Label Element="span">{data.subHeading}</Label>}
          </div>
        )}

        {data.anchorList && (
          <VideoGalleryLandscape
            title={data.anchorListTitle || "videos"}
            items={data.anchorList}
            spotLight={{
              leftIcon: <Arrow direction="left" />,
              rightIcon: <Arrow />,
              playIcon: PlayButtonWhite,
              actionElement: <div></div>,
            }}
            mobileLoopingArrowsBelow={{
              showArrows: true,
              leftIcon: <Arrow size={22} direction="left" />,
              rightIcon: <Arrow size={22} />,
            }}
            Imu={<Ads.AdsSideBar />}
            hasPagination={false}
            hasSummaryItemPublishedDate={false}
          />
        )}
      </section>

      {/* Iterate through playlists after bazaar tv */}
      {data.playlists && (
        <section className={cx(styles.gutterBottom, styles.bodyColorContainer)}>
          {data.playlists.map(
            (playlist, index) =>
              playlist.items.length > 0 && (
                <Fragment key={index}>
                  <div
                    className={cx(
                      styles.videoGridContainer,
                      styles.bodyContainer,
                    )}
                  >
                    {playlist.items.every((item) => item.videoUrl) ? (
                      // Use PortraitScroll otherwise
                      <PortraitScroll
                        leftIcon={whiteArrowLeft}
                        noOfItemsInView={3}
                        rightIcon={whiteArrowRight}
                        title={playlist.title}
                        items={playlist.items}
                      />
                    ) : (
                      // Use LandscapeScroll if every item has both image and title
                      <LandscapeScroll
                        items={playlist.items}
                        leftIcon={BlackArrowLeft}
                        noOfItemsInView={3}
                        rightIcon={BlackArrowRight}
                        title={playlist.title}
                      />
                    )}
                  </div>
                  {index === 1 && (
                    // Leaderboard 2
                    <Ads.AdsMidContent />
                  )}
                </Fragment>
              ),
          )}
        </section>
      )}

      {/* Additional sections... */}
    </Fragment>
  );
}

export default VideoLandingLayout;
